import { FACEBOOK_ID } from '../config/social/config';
import { setLoadingState } from '../redux/actions/loading';
import { LOADING_STATUS } from '../redux/reducers/loading';
import { loginUser, getFbUserProfile } from './facebookServiceInternal';

const APP_ID =
  FACEBOOK_ID[process.env.NEXT_PUBLIC_ENVIRONMENT || 'dev'][
    process.env.NEXT_PUBLIC_SITE || 'wowcher'
  ];

export const loadFacebookScript = (callback) => {
  (function (d, s, id) {
    let js = {};
    const fjs = d.getElementsByTagName(s)[0];

    if (d.getElementById(id)) {
      if (callback) callback();

      return;
    }

    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);

    js.onload = () => {
      if (callback) callback();
    };
  })(document, 'script', 'facebook-jssdk');
};

export const initFB = async () => {
  window.fbAsyncInit = function () {
    if (!window.FB) {
      console.error('FB is not defined');

      return;
    }

    window.FB.init({
      // enable cookies to allow the server to access the session
      appId: APP_ID,
      cookie: true,
      version: 'v2.5',
      xfbml: true,
    });
  };
};

export const loginFB = async (toast, dispatch) => {
  const statusConnected = 'connected';

  try {
    window.FB.login(
      (response) => {
        if (!response.authResponse) {
          dispatch(setLoadingState(LOADING_STATUS.READY));

          return;
        }

        if (response.status === statusConnected) {
          loginUser(response.authResponse, toast, dispatch);
        } else {
          // Not logged into your webpage or we are unable to tell.
          dispatch(setLoadingState(LOADING_STATUS.READY));
        }
      },
      {
        return_scopes: true,
        scope: 'email',
      },
    );
  } catch (error) {
    dispatch(setLoadingState(LOADING_STATUS.READY));
    throw new Error(error);
  }
};

export const fetchDataFacebook = async (toast, dispatch) => {
  try {
    window.FB.api(
      '/me',
      {
        fields: [
          'email',
          'location',
          'name',
          'id',
          'first_name',
          'last_name',
          'picture',
        ],
      },
      (user) => {
        getFbUserProfile(user, toast, dispatch);
      },
    );
  } catch (error) {
    dispatch(setLoadingState(LOADING_STATUS.READY));
    throw new Error(error);
  }
};
