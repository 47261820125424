import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import COOKIES from '../../config/cookies/cookies';
import { SIGN_IN_WITH_FACEBOOK } from '../../config/text/text';
import { setCookie } from '../../helpers/cookieSetter';
import {
  loadFacebookScript,
  loginFB,
  initFB,
} from '../../helpers/facebookService';
import { setLoadingState } from '../../redux/actions/loading';
import { LOADING_STATUS } from '../../redux/reducers/loading';
import useToast from '../_generic/toast/UseToast';
import MediaButton from './MediaButton';

const FacebookButton = ({ isLoginPage = false }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    loadFacebookScript(facebookInit);
  }, []);

  const facebookInit = () => {
    initFB();
  };

  const facebookLogin = () => {
    if (isLoginPage) setCookie({ key: COOKIES.emailOptOut, value: true });
    dispatch(setLoadingState(LOADING_STATUS.LOADING));
    loginFB(toast, dispatch);
  };

  return (
    <MediaButton
      ico={faFacebookSquare}
      mediaClick={facebookLogin}
      title={SIGN_IN_WITH_FACEBOOK}
      type="facebook"
    />
  );
};

FacebookButton.propTypes = {
  isLoginPage: PropTypes.bool,
};

export default FacebookButton;
