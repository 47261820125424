import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { LINK_FORGOT_PASSWORD } from '../../config/links/links';
import {
  FORGOT_YOUR_PASSWORD,
  CREATE_PASSWORD_FOR_THIS_ACCOUNT,
} from '../../config/text/text';
import { redirectToURL } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';

const ForgotPassword = ({ email, isFBOnly }) => {
  const theme = useContext(ThemeContext);
  const router = useRouter();

  const getLink = (event) => {
    event.preventDefault();
    if (email) {
      const redirectURL = `${LINK_FORGOT_PASSWORD}?email=${email}`;
      redirectToURL(redirectURL, router);

      return;
    }
    redirectToURL(LINK_FORGOT_PASSWORD, router);
  };

  return (
    <>
      <p className="text-right">
        <em>
          <Link href="#">
            <a onClick={getLink} onKeyDown={getLink} role="link" tabIndex={0}>
              {isFBOnly
                ? CREATE_PASSWORD_FOR_THIS_ACCOUNT
                : FORGOT_YOUR_PASSWORD}
            </a>
          </Link>
        </em>
      </p>
      <style jsx>{`
        .text-right a {
          color: ${theme.colors.primary};
        }
      `}</style>
    </>
  );
};

ForgotPassword.propTypes = {
  email: PropTypes.string,
  isFBOnly: PropTypes.bool,
};

ForgotPassword.defaultProps = {
  email: '',
  isFBOnly: false,
};

export default ForgotPassword;
