import Cookies from 'react-cookies';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import COOKIES from '../config/cookies/cookies';
import { URLFBLOGIN } from '../config/setup/setup';
import httpCommonHeaders from './httpCommonHeaders';
import { getUserLocationShortName } from './user';

export const getFBUser = async (result) => {
  try {
    const { data } = await axios({
      cache: false,
      data: {
        requestLogin: {
          accessToken: result.accessToken,
          emailOptOut: Cookies.load(COOKIES.emailOptOut) === 'true',
          providerId: 'facebook',
          providerUserId: result.userID,
        },
      },
      headers: httpCommonHeaders(),
      method: 'post',
      url: URLFBLOGIN,
      withCredentials: true,
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const showErrorToast = (message, toast) => {
  toast.addToast(message, 'toast-error');
};
