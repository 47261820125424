import cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import { AN_ERROR_OCCURED, SUBSCRIPTION_ERROR } from '../config/text/text';
import { setLoadingState } from '../redux/actions/loading';
import { getProfile } from '../redux/actions/user';
import { LOADING_STATUS } from '../redux/reducers/loading';
import commonCookiesOptions from './commonCookiesOptions';
import { fetchDataFacebook } from './facebookService';
import { getFBUser, showErrorToast } from './facebookServiceAPI';
import { registerSponsoredSearchSessionId } from './sponsoredSearch';
import { subscribeUser } from './subscribe';

export const loginUser = async (result, toast, dispatch) => {
  try {
    const response = await getFBUser(result);
    if (!response.data) {
      showErrorToast(AN_ERROR_OCCURED, toast);
    }
    await registerSponsoredSearchSessionId();
    fetchDataFacebook(toast, dispatch);
  } catch (error) {
    showErrorToast(error.message || AN_ERROR_OCCURED, toast);
  }
};

export const subscribeFBUser = async (email) => {
  try {
    await subscribeUser(email);
  } catch (error) {
    console.warn(error); // TODO: Add logging for JS errors
    console.error(SUBSCRIPTION_ERROR);
  }
};

export const getFbUserProfile = (user, toast, dispatch) => {
  if (
    user.picture &&
    user.picture.data &&
    typeof user.picture.data.url === 'string'
  ) {
    // user_profile picture
    cookies.save(
      COOKIES.profile_image_url,
      user.picture.data.url,
      commonCookiesOptions,
    );
  }
  const emailOptOut = cookies.load(COOKIES.emailOptOut) === 'true';
  if (!emailOptOut) subscribeFBUser(user.email);
  dispatch(getProfile());
  dispatch(setLoadingState(LOADING_STATUS.READY));
};
