import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import cookie from 'react-cookies';
import COOKIES from '../../config/cookies/cookies';
import { DELETE_ACCOUNT, CANCEL_DELETION } from '../../config/text/text';
import { removeCookie } from '../../helpers/cookieSetter';
import ThemeContext from '../../providers/ThemeProvider';
import Message from '../_generic/Message';

const DeleteAccountMessage = ({ email }) => {
  const theme = useContext(ThemeContext);
  const [isShow, setIsShow] = useState(true);

  const deleteCookie = () => {
    if (cookie.load(COOKIES.deleteEmail)) {
      removeCookie(COOKIES.deleteEmail);
    }
    if (cookie.load(COOKIES.postLoginUrl)) {
      removeCookie(COOKIES.postLoginUrl);
    }
    setIsShow(false);
  };

  if (!isShow) return null;

  return (
    <>
      <div className="delete-account">
        <Message
          html
          iconPosition="left"
          message={DELETE_ACCOUNT(email)}
          type="ERROR"
        />
        <a
          className="delete-account__link"
          onClick={deleteCookie}
          onKeyDown={deleteCookie}
          role="link"
          tabIndex="0"
        >
          {CANCEL_DELETION}
        </a>
      </div>

      <style jsx>{`
        .delete-account {
          position: relative;
        }
        :global(.delete-account .message) {
          padding-bottom: 30px;
        }
        .delete-account__link {
          bottom: 14px;
          color: ${theme.commoncolors.alert};
          cursor: pointer;
          left: 54px;
          position: absolute;
          z-index: 999;
        }
      `}</style>
    </>
  );
};

DeleteAccountMessage.propTypes = {
  email: PropTypes.string,
};

export default DeleteAccountMessage;
