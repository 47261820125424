import PropTypes from 'prop-types';
import React from 'react';
import { BRAND_WOWCHER, BRAND_LIVINGSOCIAL } from '../../config/setup/setup';
import FacebookButton from '../mediaButtons/FacebookButton';
import GoogleButton from '../mediaButtons/GoogleButton';

const LoginMediaButtons = ({ isLoginPage = false }) => {
  const Environment = process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER;
  const getMediaButtons = () => {
    if (Environment === BRAND_LIVINGSOCIAL) {
      return (
        <>
          <div className="col-12 col-md-6 text-center text-md-right">
            <GoogleButton isLoginPage={isLoginPage} />
          </div>
          <div className="col-12 col-md-6 text-center text-md-left">
            <FacebookButton isLoginPage={isLoginPage} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="col-12 text-center">
            <GoogleButton isLoginPage={isLoginPage} />
          </div>
        </>
      );
    }
  };

  return <div className="row media-login">{getMediaButtons()}</div>;
};

LoginMediaButtons.propTypes = {
  isLoginPage: PropTypes.bool,
};

export default LoginMediaButtons;
