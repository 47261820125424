// Chrome has a problem with disabling autofill,
// two inputs are a hack to prevent auto-filling the login form:
// https://www.xspdf.com/resolution/53179114.html

// The reason for disabling autofill:
// if you change between registration and login blocks,
// with autofill you will get the email validation triggered all the time
// if the auto-filled login form contains the email that wasn't registered to the system
// you will be redirected to the registration form all the time without
// an opportunity to change your login details.

export const DisableAutofill = () => {
  return (
    <>
      <input onChange={() => {}} type="hidden" value="something" />
      <input className="d-none" onChange={() => {}} type="email" value="" />
      <input className="d-none" onChange={() => {}} type="password" value="" />
    </>
  );
};
